import { template as template_3af7ae99af9d4c6d9808ba5dc7aca500 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3af7ae99af9d4c6d9808ba5dc7aca500(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
