import { template as template_b39efff7708e4110ac523b78ce262e51 } from "@ember/template-compiler";
const WelcomeHeader = template_b39efff7708e4110ac523b78ce262e51(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
