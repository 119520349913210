import { template as template_d7f9d1e971784f9187464c4031d160f6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d7f9d1e971784f9187464c4031d160f6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
